import store from './../store'

let filterParams = function (obj) {
  let _newPar = {};
  for (let key in obj) {
    //如果对象属性的值不为空，就保存该属性（如果属性的值为0，保存该属性。如果属性的值全部是空格，属于为空。）
    if ((obj[key] === 0 || obj[key] || obj[key] === false) && obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
      //记录属性
      _newPar[key] = obj[key];
    }
  }
  //返回对象
  return _newPar;
}

const getSignUrl = function (code) {
  const host = process.env.VUE_APP_SIGN;
  return `${host}/#/sign/${code}`
}

const filterAuthColumns = (columns) => {
  return columns.filter(i => {
    let auth = i.auth
    let flag = false
    if(auth === undefined || auth.length === 0) return true
    for(let item of auth) {
      if (store.state.permissionList.includes(item)) {
        flag = true
      }
    }
    return flag
  })
}

const debounce = (fn, delay) => {
  let timer = null
  return function () {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, [...arguments])
      timer = null
    }, delay)
  }
}

export default {
  filterParams,
  getSignUrl,
  filterAuthColumns,
  debounce
}