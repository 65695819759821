export const TELL_PHONE = /^1[3|4|5|6|7|8|9][0-9]{9}$/; // 手机号码
export const NUMBER = /^[0-9]{6}/

export function checkValidator(value, callback, reg, msg) {
  const blen = reg.test(value);
  if (!!value && !blen) {
    callback(msg);
  }
  callback();
}
