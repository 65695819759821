<template>
  <a-card :bordered="false">
    <a-row class="query-row" type="flex" :gutter="12">
      <a-col style="width: 220px">
        <span>手机号：</span>
        <a-input
          v-model="queryData.mobile"
          allowClear
          placeholder="请输入"
        ></a-input>
      </a-col>
      <a-col style="width: 220px">
        <span>会议码：</span>
        <a-input
          v-model="queryData.code"
          allowClear
          placeholder="请输入"
        ></a-input>
      </a-col>
      <a-col style="width: 200px">
        <span>当前状态：</span>
        <a-select v-model="queryData.status" allowClear placeholder="请选择">
          <a-select-option :value="1">正常</a-select-option>
          <a-select-option :value="0">已被撤销</a-select-option>
        </a-select>
      </a-col>

      <a-col>
        <a-button @click="search" type="primary" style="margin-right: 12px">
          查询
        </a-button>
        <a-button type="primary" @click="exportData()">导出</a-button>
      </a-col>
    </a-row>
    <a-row>
      <a-col>
        <a-button type="primary" @click="addLecturer"> 添加讲师 </a-button>
      </a-col>
    </a-row>

    <a-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      :rowKey="(record) => record.mobile"
      :loading="loading"
      :scroll="{ x: true }"
      size="default"
      @change="handleTableChange"
    >
      <span slot="historyFansNumTitle">
        历史总粉丝数
        <a-tooltip>
          <template slot="title">
            扫过该讲师会议码的历史总人数（排重）
          </template>
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
      <span slot="currentFansNumTitle">
        当前粉丝数
        <a-tooltip>
          <template slot="title"> 当前还和该讲师处于绑定关系的人数 </template>
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
      <span slot="firstOrderNumTitle">
        首单推荐数
        <a-tooltip>
          <template slot="title">
            和该讲师保持绑定关系时下了首单的用户数
          </template>
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
      <span slot="activeFansNumTitle">
        激活人数
        <a-tooltip>
          <template slot="title">
            和该讲师保持绑定关系时完成自己身份激活（以点亮那一刻为准）的人数
          </template>
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
      <span slot="pushFansNumTitle">
        直推人数
        <a-tooltip>
          <template slot="title">
            和该讲师保持绑定关系的用户完成的直推人数（首单）
          </template>
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
      <span slot="saledMoneyTitle">
        销售业绩
        <a-tooltip>
          <template slot="title">
            和该讲师保持绑定关系的用户自己下单的销售总额（包括自己自购）
          </template>
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
      <span slot="pushSaledMoneyTitle">
        直推销售业绩
        <a-tooltip>
          <template slot="title">
            和该讲师保持绑定关系的用户推荐他人下单的销售总额（包括他人自购）
          </template>
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
      <div class="remark-wrap" slot="remark" slot-scope="text, record">
        {{ record.remark }}
      </div>
      <div slot="action" slot-scope="text, record">
        <a @click="showQrcode(record)">查看二维码</a>
        <a-divider type="vertical" />
        <a @click="showEditModal(record)">编辑资料</a>
        <a-divider type="vertical" />
        <a @click="changeStatus(record)">{{
          record.status === 1 ? '撤销讲师' : '启用讲师'
        }}</a>
      </div>
    </a-table>

    <a-modal
      v-drag-modal
      :destroyOnClose="true"
      v-model="visible"
      :title="title"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
        <a-form-item label="手机号">
          <a-input
            :maxLength="11"
            v-decorator="[
              'mobile',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入11位正确的手机号码',
                  },
                  { validator: mobileValidator },
                ],
                initialValue: currentData.mobile,
              },
            ]"
            :disabled="!!currentData.mobile"
          />
        </a-form-item>

        <a-form-item label="姓名">
          <a-input
            :maxLength="10"
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: '请输入姓名' }],
                initialValue: currentData.name,
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="会议码">
          <a-input
            :maxLength="6"
            v-decorator="[
              'code',
              {
                rules: [
                  { required: true, message: '请输入6位数字的会议码' },
                  {
                    validator: codeValidator,
                  },
                ],
                initialValue: currentData.code,
              },
            ]"
            :disabled="!!currentData.mobile"
            style="width: 50%; margin-right: 20px"
          />
          <a-button
            type="primary"
            :disabled="!!currentData.mobile"
            @click="createCode()"
          >
            随机生成会议码
          </a-button>
        </a-form-item>

        <a-form-item label="备注">
          <a-textarea
            :maxLength="50"
            v-decorator="[
              'remark',
              {
                rules: [{ max: 50, message: '字数不超过50' }],
                initialValue: currentData.remark,
              },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <a-modal
      v-drag-modal
      :destroyOnClose="true"
      v-model="visibleQrcode"
      title="讲师二维码"
      :footer="null"
      :width="350"
      @cancel="visibleQrcode = false"
    >
      <div class="qrcode">
        <qrcode-vue :value="qrcodeValue" :size="200"></qrcode-vue>
      </div>
    </a-modal>

    <!--导出modal-->
    <ExportModal
      ref="exportModal"
      :exportUrl="exportUrl"
      :params="handleParams()"
      :total="pagination.total"
    >
    </ExportModal>
  </a-card>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import ExportModal from '@/components/ExportModal';
import api from '@/api';
import { checkValidator, TELL_PHONE, NUMBER } from '../../libs/validator';
import utils from '../../libs/util';
import { SUCCESS_CODE } from '../../config';

export default {
  components: {
    QrcodeVue,
    ExportModal
  },
  data() {
    return {
      exportUrl: api.lecturer.exportOrderList,
      loading: false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      visible: false,
      visibleQrcode: false,
      title: '添加讲师',
      tableData: [
        // {
        //   mobile: '13760743702',
        //   name: '丹丹',
        //   status: 1,
        //   code: 667822,
        //   historyFansNum: 100,
        //   currentFansNum: 50,
        //   firstOrderNum: 34,
        //   activeFansNum: 2,
        //   pushFansNum: 4,
        //   saledMoney: 1222,
        //   pushSaledMoney: 88,
        //   createTime: 1647242691,
        //   remark: 'this is a remark'
        // }
      ],
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        size: 'small',
        showLessItems: true,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: total => `共 ${total} 条`
      },
      queryData: {},
      qrcodeValue: '',
      currentData: {},
      columns: [
        {
          title: '序号',
          align: 'center',
          ellipsis: true,
          customRender: (text, record, index) => {
            return index + 1;
          }
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          align: 'center'
        },
        {
          title: '姓名',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: '会议码',
          dataIndex: 'code',
          align: 'center',
          ellipsis: true
        },
        {
          title: '当前状态',
          dataIndex: 'status',
          align: 'center',
          ellipsis: true,
          customRender: text => {
            return text ? '正常' : '已被撤销';
          }
        },
        {
          slots: { title: 'historyFansNumTitle' },
          dataIndex: 'historyFansNum',
          align: 'center',
          ellipsis: true
        },
        {
          slots: { title: 'currentFansNumTitle' },
          dataIndex: 'currentFansNum',
          align: 'center',
          ellipsis: true
        },
        {
          slots: { title: 'firstOrderNumTitle' },
          dataIndex: 'firstOrderNum',
          align: 'center',
          ellipsis: true
        },
        {
          slots: { title: 'activeFansNumTitle' },
          dataIndex: 'activeFansNum',
          align: 'center',
          ellipsis: true
        },
        {
          slots: { title: 'pushFansNumTitle' },
          dataIndex: 'pushFansNum',
          align: 'center',
          ellipsis: true
        },
        {
          slots: { title: 'saledMoneyTitle' },
          dataIndex: 'saledMoney',
          align: 'center',
          ellipsis: true
        },
        {
          slots: { title: 'pushSaledMoneyTitle' },
          dataIndex: 'pushSaledMoney',
          align: 'center',
          ellipsis: true
        },
        {
          title: '成为讲师时间',
          dataIndex: 'createTime',
          align: 'center',
          ellipsis: true
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'remark' }
        },
        {
          title: '操作',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    };
  },
  methods: {
    addLecturer() {
      this.title = '添加讲师';
      this.handleVisible(true);
    },
    handleVisible(flag) {
      this.visible = flag;
      if (!flag) {
        this.currentData = {};
      }
    },
    showEditModal(record) {
      this.currentData = record;
      this.title = '编辑资料';
      this.handleVisible(true);
    },
    async editLecturer(record) {
      const { id, name, remark } = record;
      const { code, msg } = await api.lecturer.editLecturer({
        id,
        name,
        remark
      });
      if (code === SUCCESS_CODE) {
        this.handleVisible(false);
        this.$message.success(msg);
        this.getTableData();
      } else {
        this.$message.error(msg);
      }
    },
    async appendLecturer(params) {
      const { code, msg } = await api.lecturer.addLecturer(params);
      if (code === SUCCESS_CODE) {
        this.handleVisible(false);
        this.$message.success(msg);
        this.getTableData();
      } else {
        this.$message.error(msg);
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
          const param = {
            ...values
          };
          this.currentData.id
            ? this.editLecturer({ ...param, id: this.currentData.id })
            : this.appendLecturer(param);
        }
      });
    },
    handleCancel() {
      this.handleVisible(false);
    },
    async createCode() {
      const { code, data } = await api.lecturer.meetingCode();
      if (code === SUCCESS_CODE) {
        this.form.setFieldsValue({
          code: data.code
        });
      }
    },
    mobileValidator(_, value, callback) {
      checkValidator(value, callback, TELL_PHONE, '请输入11位正确的手机号码');
    },
    codeValidator(_, value, callback) {
      checkValidator(value, callback, NUMBER, '请输入6位数字的会议码');
    },
    showQrcode(record) {
      this.visibleQrcode = true;
      this.qrcodeValue = utils.getSignUrl(record.code);
    },
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      const param = {
        pageNum: current,
        pageSize
      };
      this.getTableData(param);
    },
    async getTableData(options) {
      this.loading = true;
      const params = {
        pageNum: 1,
        pageSize: this.pagination.pageSize,
        ...options
      };
      if (this.queryData.mobile) {
        params.mobile = this.queryData.mobile;
      }
      if (this.queryData.status || this.queryData.status === 0) {
        params.status = this.queryData.status;
      }
      if (this.queryData.code) {
        params.code = this.queryData.code;
      }

      this.pagination = {
        ...this.pagination
      };
      const { code, data } = await api.lecturer.lecturerList(params);
      if (code === SUCCESS_CODE) {
        const { dataList = [], pageNum, pageSize, total } = data;
        this.tableData = dataList;
        this.pagination = {
          ...this.pagination,
          current: pageNum,
          pageSize,
          total
        };
      }
      this.loading = false;
    },
    search() {
      this.getTableData();
    },
    exportData() {
      this.$refs.exportModal.show();
    },
    handleParams() {
      const { code, mobile, status } = this.queryData;

      const params = {
        pageNum: this.pagination.pageNum,
        pageSize: this.pagination.pageSize
      };

      if (mobile) {
        params.mobile = this.queryData.mobile;
      }
      if (status || status === 0) {
        params.status = this.queryData.status;
      }
      if (code) {
        params.code = this.queryData.code;
      }

      return params;
    },
    async changeStatus(record) {
      const { status, id } = record;
      const func = status === 1 ? 'revokeLecturer' : 'activeLecturer';
      const { code, msg } = await api.lecturer[func]({ id });
      if (code === SUCCESS_CODE) {
        this.handleVisible(false);
        this.$message.success(msg);
        this.getTableData();
      } else {
        this.$message.error(msg);
      }
    }
  },
  mounted() {
    this.getTableData();
  }
};
</script>

<style lang="less" scoped>
.qrcode {
  margin: 30px 20px;
  text-align: center;
}
.remark-wrap {
  width: 250px;
  white-space: normal;
  text-align: left;
}
</style>